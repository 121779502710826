import { FALLBACK_LANGUAGE, fury } from '@netfleets/fms-sdk';
import { useLocalStorage } from '@netfleets/frontend-components';
import React, { createContext, useContext, useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import i18next from '../../i18n/instance';
import { ADDITIONAL_SUPPORTED_BROWSER_LANGUAGES, AVAILABLE_LANGUAGES } from '../../shared/languageConfig';

const getNavigatorLanguage = (): string => navigator.language;

export const getDefaultLanguage = (navigatorLanguage: string = getNavigatorLanguage()): string => {
  let lang = navigatorLanguage; // example: de-DE or en-US or en-UK

  if (lang in ADDITIONAL_SUPPORTED_BROWSER_LANGUAGES) {
    lang = ADDITIONAL_SUPPORTED_BROWSER_LANGUAGES[lang as keyof typeof ADDITIONAL_SUPPORTED_BROWSER_LANGUAGES];
  }

  const possibleLanguageOptions = [lang, lang.split('-')[0]];

  return (
    possibleLanguageOptions.find((languageOption) => Object.keys(AVAILABLE_LANGUAGES).includes(languageOption)) ||
    FALLBACK_LANGUAGE
  );
};

interface LanguageContext {
  language: string;
  saveLanguage: (newLanguage: string, idToken: string) => Promise<Response>;
  updateLanguage: React.Dispatch<string>;
}

const LanguageContext = createContext<LanguageContext>({} as LanguageContext);

export const LanguageProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [language, setLanguage] = useLocalStorage('korg.language', { raw: true, initialValue: getDefaultLanguage() });

  useEffect(() => {
    propagateNewLanguage(language);
  }, [language]);

  useEffect(() => {
    const languageHandlerRef = fury.registerLanguageHandler((newLanguageKey) => {
      i18next.changeLanguage(newLanguageKey);
    }, true);

    return () => fury.unregisterLanguageHandler(languageHandlerRef);
  }, []);

  const propagateNewLanguage = (newLanguage: string) => {
    document.documentElement.setAttribute('lang', newLanguage);

    const languageEvent = new CustomEvent('korg.languageChanged');
    window.dispatchEvent(languageEvent);
  };

  const saveLanguage = async (newLanguage: string, idToken: string): Promise<Response> =>
    fetch('/korg/api/users/select-language', {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      }),
      body: JSON.stringify({
        selectedLanguage: newLanguage,
      }),
    });

  return (
    <LanguageContext.Provider
      value={{
        language,
        saveLanguage,
        updateLanguage: setLanguage,
      }}
    >
      <I18nextProvider i18n={i18next}>{children}</I18nextProvider>
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
