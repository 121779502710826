import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../components/provider/UserProvider';
import { FURY_ROUTE_PREFIX } from './config';

export const WithProductInfo: React.FC<React.PropsWithChildren> = (props) => {
  const { hasFleetManagementPermission } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (hasFleetManagementPermission === false) {
      navigate(`${FURY_ROUTE_PREFIX}/info`);
    }
  }, [hasFleetManagementPermission, navigate]);

  return props.children;
};
