import { Bundle, JavasciptSourceFile, SourceFile } from './types';

export const addScriptTagToHead = (id: string, src: string, integrity = '', module = false) => {
  if (!document.getElementById(`scr-${id}`)) {
    const script = document.createElement('script');
    script.id = `scr-${id}`;
    script.src = src;
    script.async = true;
    script.integrity = integrity;
    script.type = module ? 'module' : '';
    document.head.appendChild(script);
  }
};

const addLinkTagToHead = (id: string, href: string, integrity = '') => {
  if (!document.getElementById(`lnk-${id}`)) {
    const link = document.createElement('link');
    link.id = `lnk-${id}`;
    link.rel = 'stylesheet';
    link.href = href;
    link.integrity = integrity;
    document.head.appendChild(link);
  }
};

const loadAssets = (id: string, js: JavasciptSourceFile[], css: SourceFile[]) => {
  js.forEach((jsObj, index) => {
    addScriptTagToHead(`${id}-${index}`, jsObj.url, jsObj.integrity, jsObj.module);
  });

  css.forEach((cssObj, index) => {
    addLinkTagToHead(`${id}-${index}`, cssObj.url, cssObj.integrity);
  });
};

export const loadBundle = (bundle: Bundle) => {
  loadAssets(bundle.id, bundle.localConfig.js, bundle.localConfig.css ?? []);
};
