import { PREVIEW_LANGUAGES, SUPPORTED_LANGUAGE_DISPLAY_NAMES, SUPPORTED_LANGUAGES } from '@netfleets/fms-sdk';

export const PRIVACY_POLICY_LINK =
  'https://www.jungheinrich.com/privacy-policy-for-digital-services-by-jungheinrich-924562';

const ENABLE_EXTRA_LANGUAGES = window.fury ? window.fury.flag.ENABLE_EXTRA_LANGUAGES : true;

const availableLanguageKeys = [...SUPPORTED_LANGUAGES, ...(ENABLE_EXTRA_LANGUAGES ? PREVIEW_LANGUAGES : [])];
const selectableLanguages: Record<string, string> = availableLanguageKeys.reduce(
  (acc, languageKey) => ({
    ...acc,
    [languageKey]: SUPPORTED_LANGUAGE_DISPLAY_NAMES[languageKey],
  }),
  {}
);

// maps additional browser languages to our supported languages
// phraseapp might not contain languages (without country code) that might be configured in browsers
// to support such browser languages we need to map such languages to languages we have in phraseapp (with country code)
export const ADDITIONAL_SUPPORTED_BROWSER_LANGUAGES = {
  it: 'it-IT',
  da: 'da-DK',
  de: 'de-DE',
  en: 'en-GB',
  es: 'es-ES',
  pl: 'pl-PL',
  fr: 'fr-FR',
  sv: 'sv-SE',
  nl: 'nl-NL',
  'no-NO': 'nb-NO',
  'nn-NO': 'nb-NO',
  nb: 'nb-NO',
  nn: 'nb-NO',
  no: 'nb-NO',
  el: 'el-GR',
  fi: 'fi-FI',
  pt: 'pt-PT',
  cs: 'cs-CZ',
};

export const AVAILABLE_LANGUAGES = selectableLanguages;
