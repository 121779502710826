import React from 'react';
import { ReactComponent as LogoIcon } from '../../../media/logo.svg';
import styles from './NavigationBar.css';

interface NavigationBarProps {
  onLogoClick?: (event: React.MouseEvent) => void;
  primaryElement?: React.ReactNode;
  leftElements?: React.ReactNode;
  rightElements?: React.ReactNode;
}

export const NavigationBar: React.FC<NavigationBarProps> = (props) => (
  <div className={styles.wrapper}>
    <div className={styles.leftLinks}>
      {props.primaryElement}
      <div className={styles.leftElements}>{props.leftElements}</div>
    </div>

    <a href="/" id="jh-logo" className={styles.jhLogo} onClick={props.onLogoClick}>
      <LogoIcon />
    </a>

    <div className={styles.rightLinks}>
      <div className={styles.rightElements}>{props.rightElements}</div>
    </div>
  </div>
);
