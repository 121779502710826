import { Tooltip } from '@netfleets/frontend-components';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './NavigationMenuLink.css';

interface NavigationMenuLinkProps {
  text: string;
  href: string;
  isActive?: boolean;
  isDisabled?: boolean;
  isSmall?: boolean;
  isExtern?: boolean;
  onClick?: () => void;
  badge?: React.ReactNode;
  tooltip?: string;
}

export const NavigationMenuLink: React.FC<NavigationMenuLinkProps> = (props) => {
  const MenuLink = (
    <Link
      className={`${props.isSmall ? styles.small : styles.link} ${props.isActive && styles.active}`}
      to={props.href}
      aria-disabled={props.isDisabled}
      onClick={props.isDisabled ? undefined : props.onClick}
      target={props.isExtern ? '_blank' : undefined}
      rel={props.isExtern ? 'noopener noreferrer' : undefined}
    >
      {props.text} {props.badge}
    </Link>
  );

  return (
    <li>
      {props.tooltip ? (
        <Tooltip content={props.tooltip} size="large" placement="bottom" disableInteractive>
          {MenuLink}
        </Tooltip>
      ) : (
        MenuLink
      )}
    </li>
  );
};
