import { Spinner } from '@netfleets/frontend-components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Page } from '../components/Page';
import styles from './NotFoundPage.css';

export const NotFound: React.FC = () => {
  const { t } = useTranslation();
  type LoadingState = 'loading' | 'showSpinner' | 'showLink';
  const [loadingState, setLoadingState] = useState<LoadingState>('loading');

  useEffect(() => {
    const spinnerTimer = setTimeout(() => setLoadingState('showSpinner'), 1000);
    const linkTimer = setTimeout(() => setLoadingState('showLink'), 3000);
    return () => {
      clearTimeout(spinnerTimer);
      clearTimeout(linkTimer);
    };
  }, []);

  return (
    <Page title={t('NotFound.TITLE')}>
      <div className={styles.loading}>
        {(loadingState === 'showSpinner' || loadingState === 'showLink') && <Spinner />}
        {loadingState === 'showLink' && (
          <Link to="/" className={styles.redirect}>
            {t('Loading.REDIRECT')}
          </Link>
        )}
      </div>
    </Page>
  );
};
