import { config } from '@react-spring/web';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextTransition } from '../../animation/TextTransition';
import { ReactComponent as MenuIcon } from './NavigationMenu.svg';
import styles from './NavigationMenuButton.css';

interface NavigationMenuButtonProps extends React.PropsWithChildren {
  activeMenuItem: string;
  active: boolean;
  onClick: () => void;
}

export const NavigationMenuButton: React.FC<NavigationMenuButtonProps> = (props) => {
  const { t } = useTranslation();

  return (
    <button
      id="navigation-menu-button"
      type="button"
      className={`${styles.menuButton} ${props.active && styles.active}`}
      onClick={props.onClick}
      title={t('Header.MENU')}
      aria-label={t('Header.MENU')}
      aria-expanded={props.active}
      aria-haspopup
    >
      <MenuIcon />
      <TextTransition springConfig={{ ...config.slow, tension: 310 }} className={styles.menuButtonText}>
        {props.activeMenuItem}
      </TextTransition>
    </button>
  );
};
