import { fury } from '@netfleets/fms-sdk';
import { Button, CloseIcon, EditIcon } from '@netfleets/frontend-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './DashboardControls.css';

interface GridControlsProps {
  editMode: boolean;
  stateChanged: boolean;
  setEditMode: (mode: boolean) => void;
  onCancelEdit: () => void;
  onSave: () => void;
}

export const DashboardControls: React.FC<GridControlsProps> = ({
  editMode,
  stateChanged,
  setEditMode,
  onCancelEdit,
  onSave,
}) => {
  const { t } = useTranslation();
  const handleCancelEdit = () => {
    fury.trackEvent('EDIT_DASHBOARD', 'DASHBOARD_EDIT_MODE_CANCELED');
    onCancelEdit();
    setEditMode(false);
  };

  const handleSave = () => {
    onSave();
    setEditMode(false);
  };

  return (
    <div className={styles.gridControls}>
      {editMode ? (
        <Button aria-label="Cancel" title="Cancel" size="small" variant="tertiary" onClick={() => handleCancelEdit()}>
          <CloseIcon />
        </Button>
      ) : (
        ''
      )}
      {editMode ? (
        <Button
          size="small"
          variant={stateChanged ? 'primary' : 'tertiary'}
          onClick={handleSave}
          disabled={!stateChanged}
        >
          {editMode ? t('Dashboard.EDIT_TILES_BUTTON_IN_EDIT_MODE') : t('Dashboard.EDIT_TILES_BUTTON')}
        </Button>
      ) : (
        <Button
          icon={!editMode ? <EditIcon size="medium" /> : undefined}
          size="small"
          variant={editMode ? 'primary' : 'tertiary'}
          onClick={() => setEditMode(true)}
        >
          {editMode ? t('Dashboard.EDIT_TILES_BUTTON_IN_EDIT_MODE') : t('Dashboard.EDIT_TILES_BUTTON')}
        </Button>
      )}
    </div>
  );
};
