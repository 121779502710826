import { ContentLayout, ContentTableLayout, Panel, Typography } from '@netfleets/frontend-components';
import React from 'react';
import { MsalLogEntry, msalLogger } from '../shared/msalLogger';
import { MsalLogsTable } from './MsalLogsTable';
import { Page } from './Page';
import { Header } from './header/Header';

interface ErrorDisplayProps {
  error: Error;
}

export const ErrorBoundaryDisplay: React.FC<ErrorDisplayProps> = ({ error }) => {
  const logs: MsalLogEntry[] = msalLogger.getLogs('desc');

  return (
    <>
      <Header />
      <Page title="Error">
        <ContentLayout>
          <Panel>
            <Typography variant="h3">An Error occurred, please try again later.</Typography>
            <br />
            <Typography variant="p">When talking to the support, please provide these details:</Typography>
            <br />
            <pre style={{ fontSize: 'medium' }}>{error.message}</pre>
            <pre style={{ fontSize: 'medium' }}>{error.stack && error.stack}</pre>
            <br />
            <br />
            <br />
            <Typography variant="p">Sorry for the inconvenience.</Typography>
          </Panel>
          {logs.length && (
            <ContentTableLayout>
              <Panel>
                <MsalLogsTable logs={logs} />
              </Panel>
            </ContentTableLayout>
          )}
        </ContentLayout>
      </Page>
    </>
  );
};
