import { FuryPage } from '@netfleets/fms-types';
import { Badge, Color, Typography, Variant, Weight } from '@netfleets/frontend-components';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FURY_ROUTE_PREFIX } from '../../../shared/config';
import { PRIVACY_POLICY_LINK } from '../../../shared/languageConfig';
import { MenuCategory } from '../../../shared/types';
import { useFuryLocation } from '../../hooks/useFuryLocation';
import styles from './NavigationMenu.css';
import { NavigationMenuLink } from './NavigationMenuLink';

interface NavigationMenuProps {
  pages: FuryPage[];
  onClose: () => void;
  menu: MenuCategory[];
}

export const NavigationMenu: React.FC<NavigationMenuProps> = ({ pages, onClose, menu }) => {
  const { t } = useTranslation();
  const location = useFuryLocation();
  const navigationMenuRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: MouseEvent) => {
    let targetNode = e.target as Element;
    while (targetNode.parentElement !== null) {
      if (
        (navigationMenuRef.current && navigationMenuRef.current.contains(targetNode)) ||
        targetNode.id === 'navigation-menu-button'
      ) {
        return;
      }

      targetNode = targetNode.parentElement;
    }

    onClose();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userPages: FuryPage[] = [
    {
      route: '/',
      elementName: '',
      translationKey: 'DASHBOARD',
      menuArea: 'top',
    },
    ...pages,
  ];

  const miscPages = pages.filter((app) => app.menuArea === 'misc');

  return (
    <>
      <div className={styles.menuOverlay} onClick={onClose} />
      <nav className={styles.wrapper} ref={navigationMenuRef}>
        {menu.map((moduleCategory) => (
          <ul className={styles.mainColumn} key={moduleCategory.title || 'overview'}>
            {moduleCategory.title ? (
              <div className={styles.headline}>
                <Typography variant={Variant.CAPTION} color={Color.BG06}>
                  {t(`Header.${moduleCategory.title}`)}
                </Typography>
              </div>
            ) : (
              <div className={styles.overviewColumn} />
            )}
            {moduleCategory.items.map((item) => {
              const foundPage = userPages.find((app) => app.translationKey === item.translationKey);

              if (!foundPage && item.hideWhenNotAvailable) {
                return null;
              }

              const tooltipText = foundPage
                ? undefined
                : item.tag
                  ? t(item.tag.toolTipTranslationKey)
                  : t('Menu.HOVER_STATED_UNBOOKED_MODULE');

              return (
                <NavigationMenuLink
                  key={item.translationKey}
                  tooltip={tooltipText}
                  badge={
                    item.tag &&
                    item.tag.showUntil > new Date() && (
                      <Badge content={t(item.tag.labelTranslationKey)} color="success" variant="square" />
                    )
                  }
                  text={t(`Apps.${item.translationKey}`)}
                  href={foundPage ? foundPage.route : '#'}
                  isActive={
                    location.baseRoute === foundPage?.route ||
                    (location.pathname === '/' && item.translationKey === 'DASHBOARD')
                  }
                  onClick={onClose}
                  isDisabled={!foundPage}
                />
              );
            })}
          </ul>
        ))}
        <ul className={styles.miscLinksColumn}>
          {miscPages.map((page) => (
            <NavigationMenuLink
              key={`Apps.${page.translationKey}`}
              isSmall
              text={t(`Apps.${page.translationKey}`)}
              href={page.route}
              onClick={onClose}
            />
          ))}
          <NavigationMenuLink
            isSmall
            isExtern
            text={t('Apps.API_DOCUMENTATION')}
            href={window.fury.config.BACKEND_API_DOCUMENTATION}
            onClick={onClose}
          />
          <NavigationMenuLink
            isSmall
            text={t('Apps.IMPRINT')}
            href={`${FURY_ROUTE_PREFIX}/imprint`}
            onClick={onClose}
          />
          <NavigationMenuLink isSmall isExtern text={t('Apps.PRIVACY')} href={PRIVACY_POLICY_LINK} onClick={onClose} />
          <NavigationMenuLink
            isSmall
            text={t('Apps.PRIVACY_PREFERENCES')}
            href={`${FURY_ROUTE_PREFIX}/privacy`}
            onClick={onClose}
          />
        </ul>
        <div className={styles.copyright}>
          <Typography variant={Variant.CAPTION} color={Color.BG06} weight={Weight.NORMAL}>
            © {new Date().getFullYear()} Jungheinrich AG
          </Typography>
        </div>
      </nav>
    </>
  );
};
