import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import React from 'react';
import { useLanguage } from './provider/LanguageProvider';

export const WithAuthentication: React.FC<React.PropsWithChildren> = (props) => {
  const { language } = useLanguage();

  return (
    <MsalAuthenticationTemplate // user logged in
      interactionType={InteractionType.Redirect}
      authenticationRequest={{
        extraQueryParameters: {
          ui_locales: language,
        },
      }}
    >
      {props.children}
    </MsalAuthenticationTemplate>
  );
};
