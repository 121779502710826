import { Bundle, MenuCategory } from './types';

export const bundles: Bundle[] = [
  {
    id: 'notifications',
    localConfig: { js: [{ url: '/notifications/static/index.js' }] },
  },
  {
    id: 'dr-strange',
    localConfig: { js: [{ url: '/dr-strange/static/index.js' }] },
  },
  {
    id: 'fleet-insights',
    localConfig: { js: [{ url: '/fleet-insights/c4h-bundle.js' }] },
  },
  {
    id: 'korg',
    localConfig: { js: [{ url: '/korg/static/index.js' }] },
  },
  {
    id: 'sam',
    localConfig: { js: [{ url: '/sam/static/main.js' }] },
  },
  {
    id: 'limits',
    localConfig: {
      js: [{ url: '/limits/static/main.js' }],
      css: [{ url: '/limits/static/style.css' }],
    },
  },
  {
    id: 'groot',
    localConfig: { js: [{ url: '/groot/static/index.js' }] },
  },
  {
    id: 'productivity',
    localConfig: { js: [{ url: '/productivity/static/main.js' }] },
  },
  {
    id: 'operations',
    localConfig: { js: [{ url: '/operations/static/index.js' }] },
  },
  {
    id: 'service-reports',
    localConfig: { js: [{ url: '/service-reports/static/index.js' }] },
  },
  {
    id: 'preopcheck',
    localConfig: {
      js: [{ url: '/preopcheck/static/index.js' }],
      css: [{ url: '/preopcheck/static/index.css' }],
    },
  },
  {
    id: 'data-insights',
    localConfig: { js: [{ url: '/data-insights/static/main.js' }] },
  },
  {
    id: 'helpcentre',
    localConfig: { js: [{ url: '/helpcenter/assets/index.js', module: true }] },
  },
  {
    id: 'jh-welcome',
    localConfig: { js: [{ url: '/welcome/assets/index.js', module: true }] },
  },
  {
    id: 'maintenance',
    localConfig: { js: [{ url: '/maintenance/static/index.js' }] },
  },
  {
    id: 'soc',
    localConfig: { js: [{ url: '/soc/static/index.js', module: true }] },
  },
  {
    id: 'api-selfservice',
    localConfig: { js: [{ url: '/api-selfservice/static/main.js', module: true }] },
  },
  {
    id: 'jh-acd-support-tool',
    localConfig: { js: [{ url: '/acd-support-tool/assets/index.js', module: true }] },
  },
  {
    id: 'jh-acd-connect-modal',
    localConfig: { js: [{ url: '/acd-connect-modal/assets/index.js', module: true }] },
  },
];

if (window.fury.flag.ENABLE_RELEASE_NOTES) {
  bundles.push({
    id: 'jh-release-notes',
    localConfig: { js: [{ url: '/release-notes/assets/main.js', module: true }] },
  });
}

export const menu: MenuCategory[] = [
  {
    items: [
      { translationKey: 'DASHBOARD' },
      { translationKey: 'USERS', hideWhenNotAvailable: true },
      { translationKey: 'API_KEYS', hideWhenNotAvailable: true },
      { translationKey: 'LOCATION_MANAGEMENT', hideWhenNotAvailable: true },
    ],
  },
  {
    title: 'BASICS',
    items: [{ translationKey: 'INVENTORIES' }, { translationKey: 'LOCATION_LIST' }],
  },
  {
    title: 'ANALYSIS',
    items: [
      { translationKey: 'FLEET_INSIGHTS' },
      { translationKey: 'OPERATIONAL_COSTS' },
      { translationKey: 'PRODUCTIVITY' },
      { translationKey: 'STATE_OF_CHARGE' },
      { translationKey: 'SERVICE_REPORTS' },
      { translationKey: 'MAINTENANCE' },
      { translationKey: 'DATA_INSIGHTS' },
    ],
  },
  {
    title: 'SAFETY',
    items: [
      { translationKey: 'ACCESS_SHOCK_MANAGEMENT' },
      { translationKey: 'OPERATIONS' },
      { translationKey: 'PREOPCHECK' },
    ],
  },
];
