import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { fury } from '@netfleets/fms-sdk';
import { useToastBar } from '@netfleets/frontend-components';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TileConfiguration } from '../../shared/tileConfig';
import { sortTiles } from '../hooks/useTiles';
import { useAuthentication } from '../provider/AuthenticationProvider';
import { useBundles } from '../provider/BundlesProvider';
import { PlainTile, Tile } from '../tiles/Tile';
import { TilesSkeletonLoader } from '../tiles/TilesSkeletonLoader';
import { DashboardControls } from './DashboardControls';
import { Grid } from './Grid';
import styles from './TilesContainer.css';

type TileContainerProps = {
  tileConfigurations: TileConfiguration[];
};

export const TilesContainer: React.FC<TileContainerProps> = ({ tileConfigurations }) => {
  const { t } = useTranslation();
  const { showSuccess, showError } = useToastBar();
  const { persistTileConfigurations, tiles } = useBundles();
  const [editMode, setEditMode] = useState(false);
  const { user } = useAuthentication();

  const [internalTileConfigurations, setInternalTileConfigurations] = useState<TileConfiguration[]>([]);

  const [activeId, setActiveId] = useState<string | undefined>();
  const ref = useRef<HTMLDivElement>(null);
  const sensors = useSensors(useSensor(PointerSensor));

  const canSaveBecauseSomethingChanged =
    JSON.stringify(tileConfigurations) !== JSON.stringify(internalTileConfigurations);

  const activeTileConfiguration = editMode ? internalTileConfigurations : tileConfigurations;
  const tilesToDisplay = React.useMemo(() => {
    if (!editMode) return tiles;
    return sortTiles(internalTileConfigurations)(tiles);
  }, [editMode, internalTileConfigurations, tiles]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setInternalTileConfigurations((prevTiles) => {
        const oldIndex = prevTiles.findIndex((tile) => tile.id === active.id);
        const newIndex = prevTiles.findIndex((tile) => tile.id === over?.id);
        return arrayMove(prevTiles, oldIndex, newIndex);
      });
    }

    setActiveId(undefined);
  };

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id.toString());
  }, []);

  const handleDragCancel = useCallback(() => {
    setActiveId(undefined);
  }, []);

  const handleCancelEdit = () => {
    setInternalTileConfigurations(tileConfigurations);
  };

  const handleEditMode = (editMode: boolean) => {
    if (editMode) setInternalTileConfigurations(tileConfigurations);
    fury.trackEvent('EDIT_DASHBOARD', 'DASHBOARD_EDIT_MODE_OPENED');
    setEditMode(editMode);
  };

  const objectHash = (obj = '') => {
    const hash = obj.split('').reduce((acc, char) => {
      const charCode = char.charCodeAt(0);
      return ((acc << 5) - acc + charCode) | 0;
    }, 0);

    return hash.toString();
  };

  const handleSave = () => {
    if (canSaveBecauseSomethingChanged) {
      try {
        fury.trackEvent('EDIT_DASHBOARD', 'DASHBOARD_EDIT_MODE_SAVED');
        fury.trackEvent('EDIT_DASHBOARD', `DASHBOARD_EDIT_MODE_SAVED_PER_USER_${objectHash(user?.objectId)}`);
        persistTileConfigurations(internalTileConfigurations);
        tilesToDisplay.map((tile) =>
          fury.trackEvent('EDIT_DASHBOARD', `DASHBOARD_TILE_${tile.elementName}_POSITION_${tile.id}`)
        );

        showSuccess(t('Dashboard.saveTiles.SUCCESS_MESSAGE'));
      } catch (e) {
        showError(t('Dashboard.saveTiles.ERROR_MESSAGE'));
      }
    }
  };

  const toggleTileVisibility = useCallback((customElement: string) => {
    setInternalTileConfigurations((prevGridTiles) => {
      return prevGridTiles.map((tile) => {
        if (tile.elementName === customElement) {
          if (tile.hidden) {
            fury.trackEvent('EDIT_DASHBOARD', `DASHBOARD_EDIT_MODE_TOGGLE_HIDDEN_${tile.elementName}_${tile.hidden}`);
          }

          return { ...tile, hidden: !tile.hidden };
        }

        return tile;
      });
    });
  }, []);

  return (
    <div ref={ref}>
      <div className={`${styles.editModeBackdrop} ${editMode ? styles.editMode : ''}`}>
        <div
          className={`${styles.editModeWrapper}`}
          style={{
            top: editMode && ref.current ? ref.current.getBoundingClientRect().top : 0,
            left: editMode && ref.current ? ref.current.getBoundingClientRect().left : 0,
          }}
        >
          <DashboardControls
            editMode={editMode}
            setEditMode={handleEditMode}
            onCancelEdit={handleCancelEdit}
            stateChanged={canSaveBecauseSomethingChanged}
            onSave={handleSave}
          />
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
          >
            <SortableContext disabled={!editMode} items={tilesToDisplay}>
              <Grid editMode={editMode} columns={3}>
                {tilesToDisplay
                  .map((tile) => ({
                    ...tile,
                    hidden: activeTileConfiguration.find((item) => item.id === tile.id)?.hidden,
                  }))
                  .filter((tile) => !tile.hidden || editMode)
                  .map((tile) => {
                    const initialTile = tiles.find((item) => item.id === tile.id);
                    return (
                      <Tile
                        key={tile.elementName}
                        id={tile.elementName}
                        editMode={editMode}
                        elementName={tile.elementName}
                        loading={initialTile?.loading !== undefined ? initialTile.loading : false} // TODO NF-4158: default "false" or "true"?
                        hidden={tile.hidden}
                        onToggleVisibility={toggleTileVisibility}
                      >
                        <PlainTile elementName={tile.elementName} />
                      </Tile>
                    );
                  })}
              </Grid>
            </SortableContext>
            <DragOverlay dropAnimation={null} adjustScale style={{ transformOrigin: '0 0' }}>
              {activeId ? (
                <div className={`${styles.dragOverlayContent}`}>
                  <TilesSkeletonLoader hideAnimation />
                </div>
              ) : null}
            </DragOverlay>
          </DndContext>
        </div>
      </div>
    </div>
  );
};
