import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { menu } from '../../shared/app-config';
import { FURY_ROUTE_PREFIX } from '../../shared/config';
import { useFuryLocation } from '../hooks/useFuryLocation';
import { useAuthentication } from '../provider/AuthenticationProvider';
import { useBundles } from '../provider/BundlesProvider';
import { useUser } from '../provider/UserProvider';
import { AppSwitcher } from './AppSwitcher/AppSwitcher';
import styles from './Header.css';
import { NavigationBar } from './NavigationBar/NavigationBar';
import { NavigationMenu } from './NavigationMenu/NavigationMenu';
import { NavigationMenuButton } from './NavigationMenu/NavigationMenuButton';
import { UserHeaderMenu } from './UserMenu/UserHeaderMenu';

export const Header: React.FC = () => {
  const { pages, headerItems, applicationLinks, userHeaderItems } = useBundles();
  const navigate = useNavigate();
  const { user, signOut } = useAuthentication();
  const { hasFleetManagementPermission } = useUser();
  const [navigationMenuActive, setNavigationMenuActive] = useState(false);
  const location = useFuryLocation();
  const { t } = useTranslation();

  let activePageTitle = t('Header.MENU');
  const activePage = location ? pages.find((p) => p.route === location.baseRoute) : undefined;
  if (!activePage) {
    if (location.pathname === '/') {
      activePageTitle = t('Apps.DASHBOARD');
    } else if (`${FURY_ROUTE_PREFIX}/imprint` === location.baseRoute) {
      activePageTitle = t('Apps.IMPRINT');
    } else if (`${FURY_ROUTE_PREFIX}/privacy` === location.baseRoute) {
      activePageTitle = t('Apps.PRIVACY_PREFERENCES');
    }
  } else {
    activePageTitle = t(`Apps.${activePage.translationKey}`);
  }

  const appLinks =
    user !== null && hasFleetManagementPermission
      ? applicationLinks
      : [
          {
            translationKey: 'CALL4SERVICE',
            targetUrl: window.fury.config.C4S_ENDPOINT,
            index: -1,
          },
        ];

  const showNavigationMenu = user !== null && Boolean(hasFleetManagementPermission);

  const handleNavigationBarClick = (e: React.MouseEvent) => {
    navigate('/');
    e.preventDefault();
  };

  return (
    <header id="jh-header" className={styles.headerFlex}>
      {navigationMenuActive && (
        <NavigationMenu menu={menu} pages={pages} onClose={() => setNavigationMenuActive(false)} />
      )}
      <NavigationBar
        onLogoClick={handleNavigationBarClick}
        primaryElement={
          showNavigationMenu ? (
            <NavigationMenuButton
              activeMenuItem={activePageTitle}
              active={navigationMenuActive}
              onClick={() => setNavigationMenuActive(!navigationMenuActive)}
            />
          ) : undefined
        }
        leftElements={
          <>
            {headerItems.map(({ elementName: CustomElement }) => (
              <CustomElement key={CustomElement} />
            ))}
          </>
        }
        rightElements={
          <>
            <AppSwitcher applicationLinks={appLinks} />
            {user && (
              <UserHeaderMenu
                user={user}
                userHeaderItems={userHeaderItems}
                onNavigationMenuClick={() => setNavigationMenuActive(false)}
                onSignOutClick={signOut}
              />
            )}
          </>
        }
      />
    </header>
  );
};
