import React, { Children, cloneElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import styles from './UserMenu.css';
import { ReactComponent as UserIcon } from './user.svg';

interface UserMenuItemProps {
  untranslatedLabel: string;
  icon?: React.ReactNode;
  onClick?: () => void;
}

export const UserMenuItem: React.FC<UserMenuItemProps> = (props) => {
  const { t } = useTranslation();

  return (
    <li className={props.onClick ? styles.clickable : ''} onClick={props.onClick} key={props.untranslatedLabel}>
      {t(props.untranslatedLabel)}
      {props.icon && props.icon}
    </li>
  );
};

interface UserMenuProps extends React.PropsWithChildren {
  title: string;
  onClick: () => void;
  className?: string;
}

export const UserMenu: React.FC<UserMenuProps> = ({ children, title, onClick, className = '' }) => {
  const [open, setOpen] = useState(false);
  const ref = useOutsideClick(() => setOpen(false));

  // jh-user-menu and user-button are used by the user journey tests
  return (
    <div className={`jh-user-menu ${styles.menu} ${className}`} ref={ref}>
      <button
        type="button"
        className={`user-button ${styles.button} ${open ? styles.buttonActive : ''}`}
        onClick={() => {
          setOpen(!open);
          onClick();
        }}
        title={title}
      >
        <UserIcon />
      </button>
      {open && (
        <ul>
          {Children.map(children, (child) => {
            if (!React.isValidElement<UserMenuItemProps>(child)) {
              return child;
            }

            if (child && child.props && child.props.onClick) {
              return cloneElement(child, {
                onClick: () => {
                  setOpen(false);
                  child.props.onClick?.();
                },
              });
            }

            return child;
          })}
        </ul>
      )}
    </div>
  );
};
