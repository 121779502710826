import { FuryUserHeaderItem } from '@netfleets/fms-types';
import { ModalController } from '@netfleets/frontend-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../../provider/AuthenticationProvider';
import { LanguageModal } from './LanguageModal';
import styles from './UserHeaderMenu.css';
import { UserMenu, UserMenuItem } from './UserMenu';
import { ReactComponent as LogoutIcon } from './logout.svg';

interface UserHeaderMenu {
  user: User;
  userHeaderItems: FuryUserHeaderItem[];
  onNavigationMenuClick: () => void;
  onSignOutClick: () => void;
}

export const UserHeaderMenu: React.FC<UserHeaderMenu> = (props) => {
  const { t } = useTranslation();

  return (
    <ModalController modal={(handleClose) => <LanguageModal onClose={handleClose} />}>
      {(handleClick) => (
        <UserMenu className={styles.userMenu} title={t('Header.PROFILE')} onClick={props.onNavigationMenuClick}>
          <UserMenuItem untranslatedLabel={(props.user && props.user.preferred_username) || ''} />
          <UserMenuItem untranslatedLabel="Header.LANGUAGE_SWITCHER" onClick={handleClick} />

          {props.userHeaderItems.map((item) => (
            <UserMenuItem
              onClick={() => document.body.appendChild(document.createElement(item.elementName))}
              untranslatedLabel={`Header.${item.translationKey}`}
              key={item.translationKey}
            />
          ))}

          <UserMenuItem untranslatedLabel="Header.LOGOUT" icon={<LogoutIcon />} onClick={props.onSignOutClick} />
        </UserMenu>
      )}
    </ModalController>
  );
};
