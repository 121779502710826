import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Page } from '../components/Page';
import factsheetDe from '../media/factsheet_de.pdf';
import factsheetEn from '../media/factsheet_en.pdf';
import { FURY_ROUTE_PREFIX } from '../shared/config';
import { PRIVACY_POLICY_LINK } from '../shared/languageConfig';
import styles from './ProductInfo.css';

export const ProductInfo: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Page title={t('Info.TITLE')} className="info-page-container">
      <div className={styles.productInfo}>
        <div className={styles.content}>
          <div className={styles.info}>
            <h1 className={`info-page-headline ${styles.headline}`}>{t('Info.HEADLINE')}</h1>
            <p>{t('Info.TEXT')}</p>
            <a className={styles.download} href={factsheetDe} target="_blank" rel="noopener noreferrer">
              {t('Info.LINK-DE')}
            </a>
            <a className={styles.download} href={factsheetEn} target="_blank" rel="noopener noreferrer">
              {t('Info.LINK-EN')}
            </a>
          </div>
          <div className={styles.callToAction}>
            <p>{t('Info.CALLTOACTION-TEXT')}</p>
            <a href="https://www.jungheinrich.de/?fcid=101756103520">{t('Info.CALLTOACTION-BUTTON')}</a>
          </div>
          <div className={styles.staticLinks}>
            <Link to={`${FURY_ROUTE_PREFIX}/imprint`}>{t('Info.LINK-IMPRINT')}</Link>
            <a href={PRIVACY_POLICY_LINK}>{t('Info.LINK-PRIVACY')}</a>
          </div>
        </div>
      </div>
    </Page>
  );
};
