import { FuryToolbarItem } from '@netfleets/fms-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from '../components/Page';
import { TilesContainer } from '../components/dashboard/TilesContainer';
import { Toolbar } from '../components/toolbar/Toolbar';
import { TileConfiguration } from '../shared/tileConfig';
import styles from './Overview.css';

interface OverviewProps {
  toolbarItems: FuryToolbarItem[];
  tileConfigurations: TileConfiguration[];
}

export const Overview: React.FC<OverviewProps> = ({ toolbarItems, tileConfigurations }) => {
  const { t } = useTranslation();

  return (
    <Page title={t('Apps.LOBBY')} className={styles.pageFlex}>
      <Toolbar toolbarItems={toolbarItems} />
      <TilesContainer tileConfigurations={tileConfigurations} />
    </Page>
  );
};
